import React, { useState } from "react";
import ImageBuilder from "../../commons/ImageBuilder";
import Layout from "../../commons/Layout";
import useProductSubcategory from "../../hooks/useProductSubcategory";
import ProductItemMiniLoaders from "../../commons/loaders/ProductItemMiniLoaders";
import useProductType from "../../hooks/useProductType";
import { Fade } from "react-reveal";

function BespokeGallery() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    "bespoke-sofa-and-sectionals"
  );

  const { productSubcategory, isLoading } = useProductSubcategory(
    "furniture",
    100,
    1,
    [],
    [],
    [],
    [],
    []
  );

  const { productType, isLoading: productTypeLoading } = useProductType(
    selectedTabIndex,
    100,
    1,
    [],
    [],
    [],
    [],
    []
  );

  let t = 0;

  return (
    <Layout>
      <Fade bottom>
        <div className="w-[65%] max-lg:w-[80%] max-md:w-[95%] mx-auto px-5 py-10 max-md:py-5">
          <div className="text-[60px] max-lg:text-[40px] max-[24px] font-[600]">
            Bespoke Gallery
          </div>
          <div
            className="font-[500] text-[16px] w-[68%] max-lg:w-[80%] max-md:w-[95%] mx-auto text-center text-[#667185]"
            style={{ textAlign: "center" }}
          >
            Based on the classical designs and is made from a single-mould poly
            carbonate plastic. The chairs have become extremely popular in
            recent years and are perfect fit for relaxed and more formal space.
          </div>
        </div>
      </Fade>

      <div className="w-[95%] mx-auto mt-12 max-lg:mt-6">
        {/* tabs */}
        <div className="flex w-full gap-6 max-md:gap-4 justify-between overflow-x-auto">
          {productSubcategory?.record?.Types?.map((type, index) => {
            return (
              <div
                key={index}
                className={`w-full py-1 font-[500] text-[14px] ${
                  selectedTabIndex === type?.slug
                    ? "border-[#F56630] text-[#F56630]"
                    : ""
                } border-1 border-b hover:border-[#F56630] hover:text-[#F56630] cursor-pointer whitespace-nowrap`}
                onClick={() => setSelectedTabIndex(type?.slug)}
              >
                {type?.name}
              </div>
            );
          })}
        </div>

        <div className="mt-4">
          {!isLoading && productType?.Products?.length === 0 && (
            <div>
              <ImageBuilder
                imageList={[
                  { path: "/assets/bespoke-thumbnail-7.jpg" },
                  { path: "/assets/bespoke-thumbnail-1.png" },
                  { path: "/assets/bespoke-thumbnail-7.jpg" },
                  { path: "/assets/bespoke-thumbnail-6.jpeg" },
                ]}
              />
            </div>
          )}

          {isLoading && (
            <>
              <ProductItemMiniLoaders number={3} />
            </>
          )}

          {!isLoading && productType?.Products?.length > 0 && (
            <div className="">
              {productType?.Products?.map(({ id, imageOne }) => ({
                id,
                path: imageOne,
              }))?.map((image, index) => {
                let numberOfImages = index % 2 === 0 ? 3 : 2;
                let m = t;
                t = t + numberOfImages;
                return (
                  <ImageBuilder
                    key={index}
                    numberOfImages={numberOfImages}
                    imageList={[
                      ...productType?.Products?.map(({ id, imageOne }) => ({
                        id,
                        path: imageOne,
                      }))?.slice(m, m + numberOfImages),
                    ]}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default BespokeGallery;
