import { Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import CustomInputField from "./CustomInputField";
import CustomMiniButton from "./CustomMiniButton";
import CustomTextField from "./CustomTextField";
import useContactRequest from "../hooks/useContactRequest";
import CustomSnackBar from "./CustomSnackBar";
import { useNavigate } from "react-router-dom";
import {
  DOCX_FILE_TYPE,
  JPEG_FILE_TYPE,
  JPG_FILE_TYPE,
  PDF_FILE_TYPE,
  PNG_FILE_TYPE,
  WEBP_FILE_TYPE,
} from "../constants/texts";
import { getFileTypesFriendly } from "../helpers/fileUpload";
import ConfirmationModal from "./modals/ConfirmationModal";
import RoundedMdButton from "./RoundedMdButton";
import { TEXT_FILE_TYPE } from "./../constants/texts";

const fileTypes = [
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  PDF_FILE_TYPE,
  TEXT_FILE_TYPE,
  DOCX_FILE_TYPE,
];

function GetInTouchForm({ isConsultation = true }) {
  const { sendContactRequest } = useContactRequest();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fileInput, setFileInput] = useState("");
  const fileRef = useRef();

  const navigate = useNavigate();

  return (
    <div className="w-full mt-6">
      <div className="text-left text-[18px] font-[600]">Get in touch</div>
      <div className="text-left text-[#98A2B3] text-[14px] font-[400] my-4">
        Kindly fill the form below
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          fullName: "",
          phoneNumber: "",
          description: "",
          subject: "",
          date: "",
          file: "",
          fileText: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = {
            ...values,
            containsFile: values.file ? true : false,
            isConsultation,
            type: isConsultation ? "consultation" : "contact",
          };
          sendContactRequest(data)
            .then((res) => {
              const response = res?.data?.data;
              setIsError(false);
              resetForm();
              if (isConsultation) {
                navigate("/book-consultation/" + response.requestNumber, {
                  replace: true,
                });
              } else {
                navigate("/contact-us/" + response.requestNumber, {
                  replace: true,
                });
              }
            })
            .catch((err) => {
              setSnackBarMessage(err?.message);
              setIsError(true);
              setOpenSnackBar(true);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validate={(values) => {
          const errors = {};

          if (values.file === null) {
            setFileInput("");
            setOpenModal(true);
          }

          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.fullName) {
            errors.fullName = "Full name is required";
          } else if (values.fullName.length < 4) {
            errors.fullName = "Full name should be at least 4 characters.";
          }
          if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
          } else if (!/^\d{11}$/.test(values.phoneNumber)) {
            errors.phoneNumber = "Invalid phone number";
          }
          if (!values.subject) {
            errors.subject = "Subject is required";
          }
          if (!values.description) {
            errors.description = "Description is required";
          } else if (values.description.length < 4) {
            errors.description = "Description should be at least 4 characters.";
          }
          if (isConsultation) {
            if (!values.date) {
              errors.date = "Date and time is required";
            }
          }

          return errors;
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldError }) => {
          return (
            <Form>
              <CustomInputField
                label={"Full Name"}
                type="text"
                placeholder="Please enter your full name"
                name="fullName"
              />
              <div className="flex max-sm:flex-col max-sm:gap-0 justify-between gap-4">
                <div className="w-full">
                  <CustomInputField
                    label={"Email Address"}
                    type="email"
                    placeholder="Please enter your email address"
                    name="email"
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    label={"Phone Number"}
                    type="text"
                    placeholder="Please enter your phone number"
                    name="phoneNumber"
                  />
                </div>
              </div>
              <CustomInputField
                label={"Subject"}
                type="text"
                placeholder="Please enter your subject (E.g Enquiries, Consultation)"
                name="subject"
              />
              <CustomTextField
                label="Description"
                type="text"
                placeholder="Please enter some description of your request"
                name="description"
              />
              {isConsultation && (
                <>
                  <CustomInputField
                    label="Select Date and Time"
                    type="datetime-local"
                    name="date"
                  />
                  {fileInput}
                  <CustomInputField
                    required={false}
                    label="Upload file"
                    type="file"
                    name="file"
                    fileTypes={fileTypes}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    inputRef={fileRef}
                    fileCallback={setFileInput}
                  />
                </>
              )}
              <div className="text-left">
                <CustomMiniButton
                  className="py-2 px-14 font-[600]"
                  label={isSubmitting ? "Please wait..." : "Submit"}
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(fileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default GetInTouchForm;
