import React, { useState, useEffect, useCallback } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import QuantityAdjuster from "../../commons/QuantityAdjuster";
import ViewAllButton from "../../commons/ViewAllButton";
import useUpdateCartItem from "../../hooks/useUpdateCartItem";
import useUser from "../../hooks/useUser";
import { debounce } from "lodash";
import useDeleteCartItem from "../../hooks/useDeleteCartItem";
import CartItemLoader from "../../commons/loaders/CartItemLoader";
import { getColorArray, getFileUrl } from "../../helpers/extractors";
import ColorCircle from "../../commons/ColorCircle";
import useAddToWishlist from "../../hooks/useAddToWishlist";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import AddToWishlistButton from "../../commons/AddToWishlistButton";
import CustomOutOfStockDisplay from "../../commons/CustomOutOfStockDisplay";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";

function CartItem({
  item,
  setIsError,
  setSnackBarMessage,
  setOpenSnackBar,
  setCartUpdated,
}) {
  const [quantity, setQuantity] = useState(0);
  const [selectedColor, setColor] = useState("");
  const { user } = useUser();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { updateCartItem, isLoading } = useUpdateCartItem();
  const { addToUserWishlist } = useAddToWishlist();
  const { deleteCartItem } = useDeleteCartItem();

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();

  const handleSetQuantity = (value, color) => {
    setQuantity(value);
    setIsUpdating(true);
    updateCartDetails(value, color);
  };

  const handleChangeColor = (quantity, color) => {
    setColor(color);
    setIsUpdating(true);
    updateCartDetails(quantity, color);
  };

  const updateCartDetails = (quantity, color) => {
    setIsUpdating(true);
    updateCartItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      quantity,
      productId: item?.Product.id,
      id: item?.id,
      color,
    })
      .then(() => {
        setInfoDetails({ message: "Updated", isError: false });
      })
      .catch((err) => {
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleDeleteItem = () => {
    setIsDeleting(true);
    deleteCartItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      id: item?.id,
    })
      .then(() => {
        setOpenSnackBar(true);
        setSnackBarMessage("Successfully deleted");
        setIsError(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const debounced = useCallback(debounce(handleSetQuantity, 500), []);
  const debouncedColor = useCallback(debounce(handleChangeColor, 500), []);

  useEffect(() => {
    setQuantity(item?.quantity);
    setColor(item?.color);
  }, [item]);
  return (
    <>
      {!isDeleting && !isUpdating && (
        <>
          {item?.Product?.quantity > 0 && (
            <div className="flex flex-col sm:flex-row justify-between gap-4 p-4 bg-white rounded-lg shadow-sm">
              <div className="flex gap-3 w-full sm:w-1/2">
                <div className="h-[100px] w-[100px] max-sm:h-[80px] max-sm:w-[80px] flex-shrink-0">
                  <img
                    className="w-full h-full rounded-lg object-cover"
                    src={item?.Product?.imageOne}
                    alt={item?.Product?.name}
                    loading="lazy"
                  />
                </div>
                <div className="flex flex-col justify-between gap-2">
                  <p className="font-[600] text-[16px] line-clamp-2">
                    {item?.Product?.name}
                  </p>
                  <p className="font-[500] text-[14px] text-gray-600">
                    Installation fee
                  </p>
                  {item?.Product?.colors && (
                    <div className="space-y-2">
                      <p className="font-[400] text-[14px] text-[#475367]">
                        <span>Color: </span>
                        <span className="font-[500]" style={{ color: selectedColor }}>
                          {selectedColor?.toUpperCase()}
                        </span>
                      </p>
                      <div className="flex flex-wrap gap-1">
                        {getColorArray(item?.Product?.colors)?.map((color) => (
                          <ColorCircle
                            key={color}
                            style={{ backgroundColor: color }}
                            value={color}
                            colorHex={color}
                            selectedColor={selectedColor}
                            getColor={(e) => debouncedColor(quantity, color)}
                          >
                            {color}
                          </ColorCircle>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-1/2 mt-4 sm:mt-0">
                <div className="flex flex-col justify-between flex-grow space-y-2">
                  <p className="font-[600] text-[16px]">
                    <CustomPriceDisplay
                      text={"Amount: "}
                      price={item?.Product ? item.Product?.priceAfterDiscount : 0}
                    />
                  </p>
                  <p className="font-[500] text-[14px] text-gray-600">
                    <CustomPriceDisplay
                      text={"Installation: "}
                      price={item?.Product?.installationFee ?? 0}
                    />
                  </p>
                  <div className="flex flex-wrap items-center gap-2">
                    <QuantityAdjuster
                      setQuantityValue={(e) => debounced(e, selectedColor)}
                      quantityValue={quantity}
                      maxValue={item?.Product.quantity}
                      className="text-[14px] w-[120px] py-1 px-2"
                    />
                    <AddToWishlistButton
                      color={selectedColor}
                      productId={item?.Product?.id}
                      quantity={quantity}
                      useIcon={true}
                    />
                    <button 
                      onClick={handleDeleteItem}
                      className="text-red-600 hover:text-red-700 p-2 rounded-full hover:bg-red-50 transition-colors"
                    >
                      <IoTrashBinOutline className="text-[20px]" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {item?.Product?.quantity < 1 && (
            <div className="flex flex-col sm:flex-row justify-between gap-4 p-4 bg-white rounded-lg shadow-sm opacity-60">
              <div className="flex gap-3 w-full sm:w-1/2">
                <div className="h-[100px] w-[100px] max-sm:h-[80px] max-sm:w-[80px] flex-shrink-0">
                  <img
                    className="w-full h-full rounded-lg object-cover"
                    src={item?.Product?.imageOne}
                    alt={item?.Product?.name}
                    loading="lazy"
                  />
                </div>
                <div className="flex flex-col justify-between gap-2">
                  <p className="font-[600] text-[16px] line-clamp-2">
                    {item?.Product?.name}
                  </p>
                  <p className="font-[500] text-[14px] text-gray-600">
                    Installation fee
                  </p>
                  {item?.Product?.colors && (
                    <div className="space-y-2">
                      <p className="font-[400] text-[14px] text-[#475367]">
                        <span>Color: </span>
                        <span className="font-[500]" style={{ color: selectedColor }}>
                          {selectedColor?.toUpperCase()}
                        </span>
                      </p>
                      <div className="flex flex-wrap gap-1">
                        {getColorArray(item?.Product?.colors)?.map((color) => (
                          <ColorCircle
                            key={color}
                            style={{ backgroundColor: color }}
                            value={color}
                            colorHex={color}
                            selectedColor={selectedColor}
                            getColor={(e) => debouncedColor(quantity, color)}
                          >
                            {color}
                          </ColorCircle>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-1/2 mt-4 sm:mt-0">
                <div className="flex flex-col justify-between flex-grow space-y-2">
                  <p className="font-[600] text-[16px]">
                    <CustomPriceDisplay
                      text={"Amount: "}
                      price={item?.Product ? item.Product?.priceAfterDiscount : 0}
                    />
                  </p>
                  <p className="font-[500] text-[14px] text-gray-600">
                    <CustomPriceDisplay
                      text={"Installation: "}
                      price={item?.Product?.installationFee ?? 0}
                    />
                  </p>
                  <div className="flex items-center gap-2">
                    <CustomOutOfStockDisplay
                      quantity={item?.Product?.quantity}
                      inCart={true}
                    />
                    <button 
                      onClick={handleDeleteItem}
                      className="text-red-600 hover:text-red-700 p-2 rounded-full hover:bg-red-50 transition-colors"
                    >
                      <IoTrashBinOutline className="text-[20px]" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {(isDeleting || isUpdating) && <CartItemLoader />}
    </>
  );
}

export default CartItem;
