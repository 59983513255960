import React, { useEffect, useRef } from "react";
import Layout from "../../commons/Layout";
import PageTopDescription from "../../commons/PageTopDescription";
import { bespoke_items } from "../../mock_data/bespoke_items";
import BespokeItem from "./BespokeItem";
import useFilterProducts from "../../hooks/useFilterProducts";
import { Link } from "react-router-dom";

function Bespoke() {
  const { products: response } = useFilterProducts(
    12,
    1,
    5,
    [],
    [],
    [],
    [],
    [],
    []
  );

  const containerRef = useRef(null);
  let scrollAmount = 0;
  let direction = 1;

  useEffect(() => {
    const container = containerRef.current;
    let animation;
    let isHovered = false;

    const scrollStep = () => {
      if (container && !isHovered) {
        scrollAmount += direction * 2;
        container.scrollLeft = scrollAmount;

        if (
          scrollAmount >= container.scrollWidth - container.clientWidth ||
          scrollAmount <= 0
        ) {
          direction *= -1;
        }
      }
      animation = requestAnimationFrame(scrollStep);
    };

    const handleMouseEnter = () => {
      isHovered = true;
    };

    const handleMouseLeave = () => {
      isHovered = false;
    };

    if (container) {
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
    }

    animation = requestAnimationFrame(scrollStep);

    return () => {
      cancelAnimationFrame(animation);
      if (container) {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  return (
    <Layout>
      <div className="w-[70%] max-lg:w-[80%] max-md:w-[90%] mx-auto py-8 max-md:py-4">
        <PageTopDescription
          heading={""}
          subHeading="Bespoke Service"
          description="Indulge in the luxury of uniqueness with Wudiy's Bespoke Service."
          descriptionClassName="text-[#667185]"
          subHeadingClassName="text-[80px] max-lg:text-[65px] max-md:text-[40px]"
        />
      </div>
      <div
        ref={containerRef}
        style={{ whiteSpace: "nowrap" }}
        className="flex gap-3 mb-12 max-md:mb-6 overflow-x-auto"
      >
        {response?.allProducts?.slice(0, 12).map(({ name, imageOne }, idx) => (
          <Link to={"/book-consultation"} key={idx}>
            <div className="min-w-[250px] h-[200px] max-md:min-w-[150px] max-md:h-[120px] hover:opacity-70 rounded-md">
              <img
                className="w-full h-full object-cover rounded-md"
                src={imageOne}
                alt={name}
              />
            </div>
          </Link>
        ))}
      </div>

      <div className="w-[82%] max-lg:w-[89%] max-md:w-[95%] mx-auto flex max-sm:flex-col mb-24 max-md:mb-12 gap-6">
        <a
          href="/book-consultation"
          className="flex items-center justify-between w-1/2 max-sm:w-full py-10 px-8 max-md:py-5 max-md:px-4 rounded-sm bg-[#EAECF0]"
        >
          <div className="w-[10%]">
            <svg
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M35 27.8667V33.7602C35 34.6352 34.3235 35.3612 33.4508 35.4228C32.7218 35.4743 32.1272 35.5 31.6667 35.5C16.939 35.5 5 23.561 5 8.83333C5 8.37285 5.02575 7.77812 5.07725 7.04917C5.13895 6.17647 5.8649 5.5 6.73977 5.5H12.6335C13.0613 5.5 13.4196 5.82403 13.4625 6.24967C13.5011 6.63178 13.537 6.93857 13.5701 7.17003C13.9072 9.52453 14.5959 11.7656 15.5812 13.8384C15.7393 14.1711 15.6362 14.5693 15.3364 14.7834L11.7392 17.353C13.9292 22.4685 18.0315 26.5708 23.147 28.7608L25.7118 25.1698C25.9287 24.8665 26.3317 24.7622 26.6683 24.922C28.741 25.9065 30.9818 26.5943 33.336 26.9307C33.566 26.9637 33.8708 26.9992 34.2503 27.0375C34.676 27.0805 35 27.4388 35 27.8667Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="w-[80%] text-[28px] max-lg:text-[24px] max-md:text-[20px] font-[600] text-left pl-5">
            Book Consultation
          </div>
          <div className="w-[5%] cursor-pointer">
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M8.45266 11.5012L0.203125 3.25155L2.56014 0.894531L13.1668 11.5012L2.56014 22.1077L0.203125 19.7507L8.45266 11.5012Z"
                fill="black"
              />
            </svg>
          </div>
        </a>
        <a
          href="/bespoke-gallery"
          className="flex items-center justify-between w-1/2 max-sm:w-full px-8 py-10 max-md:py-5 max-md:px-4 rounded-sm bg-[#EAECF0]"
        >
          <div className="w-[10%]">
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M3.33333 14.0008L6.66667 10.6675L15.8333 19.8342L21.6667 14.0008L26.6667 19.0008V3.83333H3.33333V14.0008ZM1.66667 0.5H28.3333C29.2538 0.5 30 1.2462 30 2.16667V28.8333C30 29.7538 29.2538 30.5 28.3333 30.5H1.66667C0.7462 30.5 0 29.7538 0 28.8333V2.16667C0 1.2462 0.7462 0.5 1.66667 0.5ZM20.8333 12.1667C19.4527 12.1667 18.3333 11.0474 18.3333 9.66667C18.3333 8.28595 19.4527 7.16667 20.8333 7.16667C22.214 7.16667 23.3333 8.28595 23.3333 9.66667C23.3333 11.0474 22.214 12.1667 20.8333 12.1667Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="w-[80%] text-[28px] max-lg:text-[24px] max-md:text-[20px] font-[600] text-left pl-5">
            Bespoke Gallery
          </div>
          <div className="w-[5%] cursor-pointer">
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M8.45266 11.5012L0.203125 3.25155L2.56014 0.894531L13.1668 11.5012L2.56014 22.1077L0.203125 19.7507L8.45266 11.5012Z"
                fill="black"
              />
            </svg>
          </div>
        </a>
      </div>

      <div className="w-[82%] max-lg:w-[89%] max-md:w-[95%] mx-auto mb-24 max-md:mb-12">
        {bespoke_items.map((item, index) => {
          return (
            <div key={index}>
              <BespokeItem {...item} reverse={index % 2 !== 0} />
              <div className="mb-20 max-md:mb-10"></div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}

export default Bespoke;
