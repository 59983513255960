import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getBenefits } from "../../services/benefits-services";
import { Zoom } from "react-reveal";

function OurWordsSection() {
  let benefits_data = [];

  // Queries
  const benefits = useQuery({
    queryKey: ["benefits"],
    queryFn: async () => {
      const result = await getBenefits();
      if (result.data.error) {
      }
      return result;
    },
    onError: (error) => {
      console.log("error when fetching benefits", error);
    },
  });

  benefits_data = benefits?.data?.data?.data;

  return (
    <div className="flex max-md:flex-col w-[95%] gap-4 max-md:gap-2 mx-auto mb-16 items-center">
      {benefits_data?.map((benefit, index) => {
        return (
          <Zoom key={index}>
            <div className="flex max-md:flex-col gap-4 max-md:gap-2 items-center">
              <div className="flex gap-3 items-center max-md:w-full">
                <div>
                  <img
                    loading="lazy"
                    src={"/assets/word_original_product.png"}
                    alt="wudify_quality_product"
                    // style={{ maxWidth: "140px", maxHeight: "40px" }}
                    className="max-w-[140px] max-h-[40px]"
                  />
                </div>
                <div className="text-left">
                  <h3 className="text-[18px] max-md:text-[14px] font-[600]">
                    {benefit.title}
                  </h3>
                  <p className="text-[12px] max-md:text-[10px] w-[100%]">
                    {benefit.description}
                  </p>
                </div>
              </div>
              {index < benefits_data.length - 1 && (
                <div className="bg-gray-600 h-8 w-[2.5px] mx-3 max-md:hidden"></div>
              )}
            </div>
          </Zoom>
        );
      })}
    </div>
  );
}

export default OurWordsSection;
