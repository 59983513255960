import { Zoom } from "react-reveal";
import { Link } from "react-router-dom";

function ImageBuilder({ imageList = [] }) {
  return (
    <div
      className={`grid grid-cols-4 max-md:grid-cols-2 gap-4 mb-3 max-md:mb-1`}
    >
      {imageList.map((image, id) => {
        return (
          <Zoom>
            <Link key={id} to={"/book-consultation"}>
              <div
                className={`bg-red-100 h-[300px] max-lg:h-[240px] max-md:h-[180px] hover:opacity-70 rounded-md`}
              >
                <img
                  className={"w-full h-full object-cover rounded-md"}
                  src={image?.path}
                  alt="subcategory"
                />
              </div>
            </Link>
          </Zoom>
        );
      })}
    </div>
  );
}

export default ImageBuilder;
