import useProductCategories from "../hooks/useProductCategories";
import { product_categories } from "../mock_data/product_categories";
import LinkItem from "./LinkItem";

function BottomHeader() {
  const { productCategories, isLoading } = useProductCategories();

  return (
    <div className="relative">
      <div className="bg-black w-full px-10 max-lg:px-6 max-md:px-3 flex max-[900px]:gap-10 items-center justify-between max-lg:justify-center relative mt-[8em] max-[1000px]:mt-36 max-sm:mt-[10em]">
        <div className="max-lg:hidden flex gap-10 max-lg:gap-6 max-md:gap-4">
          {isLoading && (
            <>
              {product_categories.map((category, idx) => {
                return (
                  <LinkItem
                    key={idx}
                    label={category?.name}
                    showDropDown
                    link={`/categories/${category?.slug}`}
                  />
                );
              })}
            </>
          )}
          {productCategories
            ?.filter((category) => category?.slug !== "bespoke")
            ?.map((category) => {
              return (
                <LinkItem
                  label={category?.label}
                  showDropDown={category?.Subcategories?.length > 0}
                  link={`/categories/${category?.slug}`}
                >
                  <div className="grid grid-cols-4 text-black w-[70em] text-left gap-12 px-4">
                    {category?.Subcategories?.map((subcat) => {
                      return (
                        <div>
                          <div
                            className="py-1.5 border-b-2 border-gray-200 text-[#FF9D21]"
                            style={{ fontSize: "14px" }}
                          >
                            <a href={`/sub-categories/${subcat?.slug}`}>
                              {subcat?.name}
                            </a>
                          </div>
                          <div className="font-normal mt-2 flex flex-col gap-1.5 text-white">
                            {subcat?.Types?.map((type) => {
                              return (
                                <div className="cursor-pointer hover:text-[#FF9D21]">
                                  <a href={`/product-types/${type?.slug}`}>
                                    {type?.name}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </LinkItem>
              );
            })}

          <a href="/bespoke-services">
            <LinkItem label={"Bespoke Service"} />
          </a>
          <LinkItem
            label={"Inspiration"}
            showDropDown={true}
            positionClassName="top-0"
            link={`/inspiration/decor-tips`}
          >
            <div className="w-[10em] text-left text-white">
              <div className="cursor-pointer hover:text-[#FF9D21]">
                <a href="/inspiration/decor-tips">Decor Tips</a>
              </div>
              <div className="cursor-pointer hover:text-[#FF9D21]">
                <a href="/inspiration/furniture-guides">Furniture Guide</a>
              </div>
            </div>
          </LinkItem>
          {/* <a href="/sales">
            <LinkItem label={"Sales"} />
          </a> */}
        </div>
        <div className="max-lg:py-4 text-[#FF9D21] hover:text-white cursor-pointer font-[500] text-[15px] max-md:text-[12px] whitespace-nowrap">
          <a href="/book-consultation">Book a Consultation</a>
        </div>
      </div>
      <div className="hidden max-lg:flex px-3 bg-black gap-10 max-lg:gap-6 max-md:gap-4 overflow-x-auto pb-3">
        {isLoading && (
          <>
            {product_categories.map((category) => {
              return (
                <LinkItem
                  label={category?.name}
                  showDropDown
                  link={`/categories/${category?.slug}`}
                />
              );
            })}
          </>
        )}
        {productCategories
          ?.filter((category) => category?.slug !== "bespoke")
          ?.map((category) => {
            return (
              <LinkItem
                label={category?.label}
                showDropDown={category?.Subcategories?.length > 0}
                link={`/categories/${category?.slug}`}
              >
                <div className="grid grid-cols-4 text-black w-[70em] max-md:grid-cols-2 max-md:w-full text-left gap-12 px-4 max-md:px-0">
                  {category?.Subcategories?.map((subcat) => {
                    return (
                      <div>
                        <div className="py-1.5 border-b-2 border-gray-200 text-[#FF9D21] text-[14px] max-md:text-[12px]">
                          <a href={`/sub-categories/${subcat?.slug}`}>
                            {subcat?.name}
                          </a>
                        </div>
                        <div className="font-normal mt-2 flex flex-col gap-1.5 text-white">
                          {subcat?.Types?.map((type) => {
                            return (
                              <div className="cursor-pointer hover:text-[#FF9D21]">
                                <a href={`/product-types/${type?.slug}`}>
                                  {type?.name}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </LinkItem>
            );
          })}

        <a href="/bespoke-services">
          <LinkItem label={"Bespoke Service"} />
        </a>
        <LinkItem
          label={"Inspiration"}
          showDropDown={true}
          childrenClassName="right-0"
          link={`/inspiration/decor-tips`}
        >
          <div className="w-[10em] text-left text-white">
            <div className="cursor-pointer hover:text-[#FF9D21]">
              <a href="/inspiration/decor-tips">Decor Tips</a>
            </div>
            <div className="cursor-pointer hover:text-[#FF9D21]">
              <a href="/inspiration/furniture-guides">Furniture Guide</a>
            </div>
          </div>
        </LinkItem>
        {/* <a href="/sales">
          <LinkItem label={"Sales"} />
        </a> */}
      </div>
    </div>
  );
}

export default BottomHeader;
