import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoCartOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomNoRecordFound from "../../commons/CustomNoRecordFound";
import CustomSnackBar from "../../commons/CustomSnackBar";
import CartItem from "./CartItem";

function CartDetails({ cartItemsData, setCartUpdated }) {
  const navigate = useNavigate();
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <div className="p-4 sm:p-5 rounded-lg bg-white shadow-md">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 sm:gap-0">
        <div className="flex items-center gap-3">
          <div className="font-[600] text-[18px] sm:text-[20px]">
            My Cart
          </div>
          <div className="w-[25px] h-[25px] sm:w-[30px] sm:h-[30px] rounded-full text-white bg-[#FF9D21] flex items-center justify-center text-[14px] sm:text-[16px]">
            {cartItemsData?.cartItems.length ?? 0}
          </div>
        </div>
        <button
          className="flex items-center gap-2 text-[14px] sm:text-[16px] font-[600] text-[#318EC9] hover:text-[#2574a9] transition-colors"
          onClick={() => navigate("/")}
        >
          <span>Continue shopping</span>
          <IoIosArrowForward className="text-lg" />
        </button>
      </div>

      {cartItemsData?.cartItems?.length === 0 && (
        <div className="mt-8 sm:mt-12">
          <CustomNoRecordFound
            buttonText={"Shop now"}
            label={"No item has been added to cart yet."}
            moreLabel={"Shop and add items to cart."}
            onButtonClick={() => navigate("/")}
          >
            <IoCartOutline className="text-8xl sm:text-[15em] text-gray-400" />
          </CustomNoRecordFound>
        </div>
      )}

      {/* Cart items */}
      <div className="mt-6 sm:mt-8 flex flex-col gap-4 sm:gap-6">
        {cartItemsData?.cartItems?.map((cartItem) => (
          <CartItem
            key={cartItem.id}
            item={cartItem}
            setOpenSnackBar={setOpenSnackBar}
            setIsError={setIsError}
            setSnackBarMessage={setSnackBarMessage}
            setCartUpdated={setCartUpdated}
          />
        ))}
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default CartDetails;
