import { useState, useEffect } from "react";
import CustomDiscountDisplay from "./CustomDiscountDisplay";
import CustomOutOfStockDisplay from "./CustomOutOfStockDisplay";
import CustomPriceDisplay from "./CustomPriceDisplay";
import useAddToWishlist from "../hooks/useAddToWishlist";
import useUser from "../hooks/useUser";
import { FiHeart } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { saveIntendedRoute } from "../local_store/intendedRouteLocalStore";
import useUserWishList from "../hooks/useUserWishlist";
import useDeleteWishlistItem from "../hooks/useDeleteWishlistItem";
import CustomSnackBar from "./CustomSnackBar";

function ProductItemMini({ product }) {
  const [currentImage, setCurrentImage] = useState(product.imageOne);
  const { addToUserWishlist } = useAddToWishlist();
  const { deleteWishListItem } = useDeleteWishlistItem();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdding, setIsAdding] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [wishlistItemId, setWishlistItemId] = useState(null);

  const isBespoke = product?.Type?.Subcategory?.Category?.name === "Bespoke";

  // Fetch wishlist status
  const { userWishListItems, isLoading: isLoadingWishlist } = useUserWishList(
    user,
    null,
    1000,
    1
  );

  useEffect(() => {
    if (userWishListItems?.rows) {
      const wishlistItem = userWishListItems.rows.find(
        (item) => item.productId === product.id
      );
      setIsInWishlist(!!wishlistItem);
      if (wishlistItem) {
        setWishlistItemId(wishlistItem.id);
      }
    }
  }, [userWishListItems, product.id]);

  // Preload all product images immediately
  useEffect(() => {
    const preloadImages = [product.imageOne, product.imageTwo].filter(Boolean);
    preloadImages.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, [product.imageOne, product.imageTwo]);

  const handleMouseEnter = () => {
    if (product.imageTwo) {
      setCurrentImage(product.imageTwo);
    }
  };

  const handleSaveItem = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Don't allow adding bespoke products to wishlist
    if (isBespoke) {
      setSnackBarMessage("Bespoke products cannot be added to wishlist. Please book a consultation instead.");
      setIsError(true);
      setOpenSnackBar(true);
      return;
    }
    
    if (!user) {
      saveIntendedRoute(location.pathname);
      navigate("/auth/login");
      return;
    }

    try {
      setIsAdding(true);
      if (isInWishlist && wishlistItemId) {
        // Remove from wishlist
        await deleteWishListItem({
          userId: user.id,
          accessToken: user.accessToken,
          id: wishlistItemId,
        });
        setIsInWishlist(false);
        setWishlistItemId(null);
        setSnackBarMessage("Item removed from wishlist");
        setIsError(false);
      } else {
        // Add to wishlist
        const response = await addToUserWishlist({
          productId: product.id,
          userId: user.id,
          accessToken: user.accessToken,
          quantity: 1,
          color: "",
        });
        // Store the new wishlist item ID
        if (response?.data?.data?.id) {
          setWishlistItemId(response.data.data.id);
        }
        setIsInWishlist(true);
        setSnackBarMessage("Item added to wishlist");
        setIsError(false);
      }
    } catch (error) {
      console.error("Error toggling wishlist status:", error);
      setSnackBarMessage(error?.response?.data?.message || "Error updating wishlist");
      setIsError(true);
    } finally {
      setIsAdding(false);
      setOpenSnackBar(true);
    }
  };

  return (
    <div className="product-item-mini h-full animate-fadeIn">
      <CustomSnackBar
        isOpen={openSnackBar}
        message={snackBarMessage}
        severity={isError ? "error" : "success"}
        onClose={() => setOpenSnackBar(false)}
      />
      <a href={`/products/${product?.slug}`} className="block h-full">
        <div 
          className="h-full flex flex-col cursor-pointer hover:opacity-70 relative shadow-lg rounded-md"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setCurrentImage(product.imageOne)}
        >
          {!isBespoke && (
            <button
              onClick={handleSaveItem}
              className="absolute right-2 top-2 z-10 bg-white/80 backdrop-blur-sm p-2 rounded-full"
            >
              {isInWishlist ? (
                <FaHeart className="text-red-500" />
              ) : (
                <FiHeart />
              )}
            </button>
          )}
          <div className="relative w-full pb-[100%] bg-gray-50">
            <img
              src={currentImage}
              alt={product.name}
              className="absolute inset-0 w-full h-full object-cover rounded-t-md animate-fastFadeIn"
              loading="eager"
            />
            <div className="absolute top-0 max-sm:-top-[5px] z-10">
              <CustomDiscountDisplay discountPrice={product?.discountPrice} />
            </div>
            <div className="absolute -top-[10px] right-0 z-10">
              {!isBespoke && (
                <CustomOutOfStockDisplay quantity={product?.quantity} />
              )}
            </div>
          </div>

          <div className="px-2 py-2 flex-1 flex flex-col">
            <div className="flex flex-wrap gap-1 items-start justify-between font-[500] text-[16px] max-lg:text-[14px] max-md:text-[12px]">
              <div className="text-left line-clamp-2">
                {product?.name}
              </div>
              {isBespoke ? (
                <div className="bg-gray-100 text-gray-600 text-xs px-2 py-1 rounded-full whitespace-nowrap">
                  Custom-made only
                </div>
              ) : product?.priceAfterDiscount > 0 ? (
                <CustomPriceDisplay price={product?.priceAfterDiscount} />
              ) : (
                ""
              )}
            </div>

            <div className="flex flex-wrap gap-1 justify-between mt-1 font-[500] text-[12px]">
              <div className="flex gap-1 items-center w-fit overflow-x-auto">
                <p className="max-sm:text-[10px] whitespace-nowrap">Colors:</p>
                <div className="flex gap-1 items-center">
                  {product?.colors?.split(",")?.map((color, idx) => (
                    <div
                      key={idx}
                      className="w-[10px] h-[10px] rounded-full border-[0.5px] border-[#888888]"
                      style={{ backgroundColor: color?.toLowerCase() }}
                    />
                  ))}
                </div>
              </div>
              <div
                className="text-gray-500"
                style={{ textDecoration: "line-through", opacity: "0.6" }}
              >
                {product?.discountPrice ? (
                  <CustomPriceDisplay price={product?.price} />
                ) : (
                  ""
                )}
              </div>
            </div>
            
            <div className="mt-auto pt-2 flex max-sm:flex-col max-sm:items-start gap-1 justify-between items-center">
              <a href={`/product-types/${product?.Type?.slug}`}>
                <div className="font-[500] text-left max-sm:text-[10px]">
                  Type: {product?.Type?.name}
                </div>
              </a>
              <div className="max-sm:text-[10px]">
                Brand: {product?.Vendor?.businessName}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default ProductItemMini;
