import { PiShoppingCartSimple } from "react-icons/pi";
import ViewAllButton from "./ViewAllButton";
import { useNavigate } from "react-router-dom";

const BookConsultationBtn = ({ product }) => {
  const navigate = useNavigate();

  return (
    <ViewAllButton
      text={"Book consultation"}
      onClick={() => navigate("/book-consultation", { state: product })}
      className="border-orange-400 text-[#FF9D21] hover:text-white hover:bg-[#FF9D21] border max-md:!py-1"
      icon={<PiShoppingCartSimple />}
    />
  );
};

export default BookConsultationBtn;
