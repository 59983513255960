import React, { useState } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import AddToCartButton from "../../commons/AddToCartButton";
import BuyNowButton from "../../commons/BuyNowButton";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import CartItemLoader from "../../commons/loaders/CartItemLoader";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import ViewAllButton from "../../commons/ViewAllButton";
import useDeleteWishlistItem from "../../hooks/useDeleteWishlistItem";
import useUser from "../../hooks/useUser";
import { getFileUrl } from "./../../helpers/extractors";
import CustomOutOfStockDisplay from "../../commons/CustomOutOfStockDisplay";
import BookConsultationBtn from "../../commons/BookConsultationBtn";

function AccountSavedItem({
  wishlist,
  setIsError,
  setSnackBarMessage,
  setOpenSnackBar,
  addToCart,
  isLoading,
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const { user } = useUser();

  const { deleteWishListItem } = useDeleteWishlistItem();

  const data = {
    ...wishlist,
    userId: user?.id,
    productId: wishlist?.Product?.id,
    accessToken: user?.accessToken,
  };

  const handleAddToCart = () => {
    addToCart(data);
  };

  const handleDeleteItem = () => {
    setIsDeleting(true);
    deleteWishListItem({
      userId: user?.id,
      accessToken: user?.accessToken,
      id: wishlist?.id,
    })
      .then(() => {
        setOpenSnackBar(true);
        setSnackBarMessage("Successfully deleted");
        setIsError(false);
      })
      .catch((err) => {
        setOpenSnackBar(true);
        setSnackBarMessage(err?.message);
        setIsError(true);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const isBespoke =
    wishlist?.Product?.Type?.Subcategory?.Category?.name === "Bespoke";

  return (
    <>
      {!isDeleting && (
        <div className="flex justify-between mb-4">
          <div className="flex gap-3 w-[70%] max-sm:w-[40%]">
            <div className="h-[120px] w-[120px] max-sm:h-[100px] max-sm:w-[100px]">
              <img
                className="w-full h-full rounded-lg"
                style={{ objectFit: "cover" }}
                src={wishlist?.Product.imageOne}
                alt=""
                loading="lazy"
              />
            </div>
            <div className="flex flex-col gap-2 max-sm:gap-1">
              <p className="font-[600] text-[18px] max-lg:text-[15px] max-sm:text-[12px]">
                {wishlist?.Product?.name}
              </p>
              <p className="font-[500] text-[14px] max-lg:text-[12px] max-sm:text-[10px]">
                Installation fee
              </p>
              <p className="font-[400] text-[14px] max-lg:text-[12px] max-sm:text-[10px] text-[#475367]">
                {wishlist?.Product?.colors && (
                  <div>
                    Color:{" "}
                    <span className="font-[500]">
                      {wishlist?.color?.toUpperCase()}
                    </span>
                  </div>
                )}
              </p>
            </div>
          </div>

          <div className="flex w-[60%] max-sm:w-[55%] justify-end">
            <div className="flex flex-col gap-2 text-right pr-10 max-md:pr-4 max-sm:pr-2">
              <p className="font-[600] text-[18px] max-lg:text-[15px] max-sm:text-[12px]">
                <CustomPriceDisplay
                  text={"Amount: "}
                  price={
                    wishlist?.Product ? wishlist.Product?.priceAfterDiscount : 0
                  }
                />{" "}
              </p>
              <p className="font-[500] text-[14px] max-lg:text-[12px] max-sm:text-[10px]">
                <CustomPriceDisplay
                  text={"Amount: "}
                  price={wishlist?.Product?.installationPrice ?? 0}
                />
              </p>
              <div className="flex gap-2 justify-end max-sm:mt-1">
                {isBespoke ? (
                  <BookConsultationBtn product={wishlist?.Product} />
                ) : wishlist?.Product?.quantity < 1 ? (
                  <CustomOutOfStockDisplay
                    quantity={wishlist?.Product?.quantity}
                  />
                ) : (
                  <div className="flex gap-2 max-sm:flex-col">
                    <AddToCartButton
                      color={wishlist?.color}
                      quantity={wishlist?.quantity}
                      productId={wishlist?.Product?.id}
                      chooseColor={wishlist?.Product?.colors}
                    />
                    <BuyNowButton
                      color={wishlist?.color}
                      quantity={wishlist?.quantity}
                      productId={wishlist?.Product?.id}
                      chooseColor={wishlist?.Product?.colors}
                      slug={wishlist?.Product?.slug}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-start" onClick={handleDeleteItem}>
            <IoTrashBinOutline className="text-[20px] max-lg:text-[16px] max-sm:text-[14px] cursor-pointer text-red-600" />
          </div>
        </div>
      )}
      {isDeleting && <CartItemLoader />}
    </>
  );
}

export default AccountSavedItem;
