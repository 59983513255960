import React from "react";
import ViewAllButton from "../../commons/ViewAllButton";
import CartPriceItem from "../../commons/CartPriceItem";
import CustomShippingFeeNotAdded from "../../commons/CustomShippingFeeNotAdded";

function CartSummary({ cartItemsData, isLoading }) {
  return (
    <>
      {!isLoading && (
        <div className="p-4 sm:p-5 rounded-lg bg-white shadow-md">
          <div className="mb-6">
            <div className="font-[600] text-[18px] sm:text-[20px]">
              Cart Summary
            </div>
          </div>

          <hr className="my-4" />

          <CartPriceItem
            title="Sub Total"
            price={`${cartItemsData?.subTotalPrice ?? 0}`}
          />

          <hr className="my-4" />

          <CustomShippingFeeNotAdded />
          
          <hr className="my-4" />

          <div className="mt-6">
            <a href="/my-cart/checkout">
              <ViewAllButton
                className="w-full py-3 text-center text-[#FF9D21] hover:text-white bg-white hover:bg-[#FF9D21] border border-orange-400 transition-colors duration-200 rounded-lg font-medium text-[15px] sm:text-[16px]"
                text="Proceed to Checkout"
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export default CartSummary;
