import { getFileUrl } from "./../helpers/extractors";

function BrandThumbnailItem({ brand }) {
  return (
    <div className="w-[200px] h-[100px] max-lg:w-[180px] max-lg:h-[90px] max-md:w-[140px] max-md:h-[70px] max-sm:w-[120px] max-sm:h-[60px] transition-transform hover:scale-105">
      <img
        className="object-contain w-full h-full"
        src={getFileUrl(brand.imagePath)}
        alt={`wudify_with_brand_${brand.name}`}
        loading="eager"
      />
    </div>
  );
}

export default BrandThumbnailItem;
