export const isFileType = (fileTypes, file) => {
  if (fileTypes && file) {
    if (fileTypes.includes(file.type)) {
      return true;
    }
  } else {
    return false;
  }
};

export const getFileTypesFriendly = (fileTypes) => {
  let text = "";
  if (fileTypes) {
    if (typeof fileTypes === "string") {
      text = fileTypes.split("/")[1];
    } else {
      for (const f of fileTypes) {
        if (f.includes("vnd")) {
          text += "docx" + ", ";
        } else {
          text += f.split("/")[1] + ", ";
        }
      }
      text = text.substring(0, text.length - 2);
    }
  }

  return text;
};

export const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
